/* You can add global styles to this file, and also import other style files */

// se establece color para el campo cuando este es inválido
.ng-touched.ng-invalid{
    border-color: #cb585a !important;
}

// se establece color para el campo cuando este es válido
.ng-touched.ng-valid{
    border-color: #37BC9B !important;
}

// se ajusta la posición de los iconos de cada campo
.has-icon-left .form-control-position{
    top: 0 !important;
}